<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-28 14:47:31
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-07 15:20:23
-->
<template>
  <div>
      <el-row>
        <el-col>
          <el-button type="primary" @click="priceRecord(list)">发起对账</el-button>
        </el-col>
      </el-row>
  
    <el-dialog
              title="提交审批"
              :visible.sync="setRightDialogVisible"
              width="70%">
              <div>
                <span> 购液企业名称：{{formInline.name }}</span> 
                <span style="margin-left: 20px;">账户余额：{{formInline.balance}}元</span>
              </div>
              <!-- 订单总数列表 -->
        <el-table  :data="TotalList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
          <el-table-column label="总车数" prop="carNumber"></el-table-column>
          <el-table-column prop="shipmentWeight" label="装车量（吨）" ></el-table-column>
          <el-table-column  label="结算量（吨）" >
            <template slot-scope="scope">
              <span>{{ !scope.row.weight?0:scope.row.weight }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结算总金额（元）" >
            <template slot-scope="scope">
              <span>{{ !scope.row.totalAmount?0:scope.row.totalAmount }}</span>
            </template>
          </el-table-column>
        </el-table>
  
        <!-- 选择订单详情列表 -->
        <el-table :data="priceList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}"  :cell-style="{color: '#0a0b1a'}">
          <el-table-column label="计划日期" prop="planTime"></el-table-column>
          <el-table-column label="订单号">
            <template slot-scope="scope">{{ scope.row.orderNumber }}</template>
          </el-table-column>
          <el-table-column prop="distributionType" label="配送方式" >
            <template slot-scope="scope">
              <span>{{scope.row.deliveryType==1?"自提":scope.row.deliveryType==2?"配送":""}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="tractorNumber" label="车辆信息" >
            <template slot-scope="scope">
          <span>{{scope.row.tractorNumber?scope.row.tractorNumber:"--"}}</span>
        </template>
          </el-table-column>
          <el-table-column prop="sourceName" label="液源" ></el-table-column>
          <el-table-column prop="settlementUnitPrice" label="结算单价（元/吨）" >
            <template slot-scope="scope">
              {{ scope.row.settlementUnitPrice?scope.row.settlementUnitPrice:0 }}
            </template>
          </el-table-column>
          <el-table-column label="装车量（吨）" >
            <template slot-scope="scope">{{ scope.row.shipmentWeight?scope.row.shipmentWeight:0 }}</template>
          </el-table-column>
          <el-table-column label="卸车量（吨）" >
            <template slot-scope="scope">{{scope.row.unloadWeight?scope.row.unloadWeight:0 }}</template>
          </el-table-column>
          <el-table-column label="结算量（吨）" >
            <template slot-scope="scope">{{ scope.row.settlementVolume?scope.row.settlementVolume:0 }}</template>
          </el-table-column>
          <el-table-column label="结算总金额（元）" >
            <template slot-scope="scope">{{ scope.row.settlementAmount?scope.row.settlementAmount:0 }}</template>
          </el-table-column>
          <el-table-column prop="amount" label="进款金额(元)" ></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setRightDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveButton">确 定</el-button>
        </span>
      </el-dialog>
      </div>
  </template>
  <script>
  import {TradeReconciliationEdit} from 'network/financial'
  export default {
    name: "EditReconciliationModal",
    props: {
      list: {
        type: Array,
      },
      formInline: {
        type:Object
      },
    },
    data() {
      return {
        property: 'value',
        setRightDialogVisible: false,  
        priceList: [],
        TotalList: [],
        // formInline: {
        //   region: ''
        // }   
      };
    },
    methods: {
      priceRecord(scope){
        console.log(this.formInline.time);
        let newData = []
        for(let i = 0; i<scope.length;i++){
          for(let j=0;j<scope[i].reconciliationOrderVO.length;j++){
            if(scope[i].reconciliationOrderVO[j].isChecked==true){
              newData.push(scope[i].reconciliationOrderVO[j])
            }
          }
        }
        this.priceList = newData
        let newInfo = [{
          carNumber: newData.length,
          shipmentWeight: newData.reduce((c, R) => c + R.shipmentWeight, 0),
          weight: newData.reduce((c, R) => Number(c) + Number(R.settlementVolume), 0),
          totalAmount: newData.reduce((c, R) => Number( c) + Number( R.settlementAmount ), 0)
        }]
        this.TotalList = newInfo
        let ListId = []
        for(let i = 0;i<newData.length;i++){
          ListId.push(newData[i].orderId)
        }
        if(this.formInline.time==null){
          return this.alertMessage('请先选择对账计划日期', 'error');
        }if(this.formInline.title==''){
          return this.alertMessage('请先输入对账单标题', 'error');
        }
        if(ListId.length==0){
          return this.alertMessage('请选择订单后发起对账', 'error');
        }
        if(scope==null||scope.length==0){
          return this.alertMessage('暂无可对账的订单', 'error');
        }
        else{
          this.setRightDialogVisible = true
        }
      },
      saveButton(){
        let ListId = []
        for(let i = 0;i<this.priceList.length;i++){
          ListId.push(this.priceList[i].orderId)
        }
        if(ListId.length!==0){
          TradeReconciliationEdit({
          orderBillIds: ListId,
          reconciliationId: this.formInline.reconciliationId,
          
          startTime: this.formInline.time[0],
          endTime: this.formInline.time[1],
          // stationId: this.formInline.stationId,
          reconciliationName: this.formInline.title,
          totalAmount: this.TotalList[0].totalAmount,
          totalWeight: this.TotalList[0].weight,
          // countCar: this.TotalList[0].carNumber,
          shipmentWeight: this.TotalList[0].shipmentWeight
        }).then(res=> {
          let result = res.data;
            if (result.code !== 200) {
              return this.alertMessage(result.msg, 'error');
            }
            this.setRightDialogVisible = false
            this.$router.replace({path: '/pages/biz/gas_factory/finance/reconciliation'})
        })
      }else if(ListId.length==0){
        return this.alertMessage('请选择订单后发起对账', 'error');
      }
      }
    }
  }
  </script>
  <style scoped>
  .el-button--text{
    color: var(--theme_bg_color);
  }
    .el-button:focus,
    .el-button:hover {
      border: 0px;
      border-color: #fff;
      color: var(--theme_bg_color);
    }
  .el-button--primary{
    background-color: var(--theme_bg_color);
    border: 1px solid var(--theme_bg_color)
  }
  .el-button--primary:focus,.el-button--primary:hover {
      border-color: var(--theme_bg_color);
      color: #fff;
    }
  </style>
  